/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider.state('post-install-multi-screen-acquisition-flow', {
    url: '/post-install-multi-screen-acquisition-flow',
    templateUrl:
      'sections/post-install-multi-screen-acquisition-flow/templates/acp-post-install-multi-screen-acquisition-flow.ng.html',
    resolve: {
      module: /* @ngInject */ function($ocLazyLoad) {
        return import(/* webpackChunkName: "acp.section.post-install-multi-screen-acquisition-flow" */ 'sections/post-install-multi-screen-acquisition-flow').then(
          $ocLazyLoad.loadModule
        );
      }
    }
  });
}

export default states;
